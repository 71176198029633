const PROFILE_TYPE = {
  ENROLLMENT: 'enrollment',
  DEVICESETTINGS: 'deviceSettings',
  APPSETUP: 'appSetup',
  APP_MANAGEMENT: 'appManagement',
  THIRD_PARTY_APPS: 'thirdPartyApps',
  DESIGNER_CONFIGURATOR: 'designerConfigurator',
  LOGGER: 'Logger'
}

const GENERAL_PAGES = [
  'New Device Setup',
  'My Devices',
  'Device Users',
  'Device Settings',
  'My Apps',
  'Design Studio',
  'Android Updates',
  'Licensing',
  'Wireless Insights',
]
const PROFILE_LEVEL_PAGES = ['My Services', 'Users']
const PAGES_LIST = {
  GENERAL_PAGES,
  PROFILE_LEVEL_PAGES,
  ALL_PAGES: [...GENERAL_PAGES, ...PROFILE_LEVEL_PAGES],
}

const DATABASE = {
  TENANT_COL: 'tenants',
  DEPLOYMENT_COL: 'deployments',
  CONFIGS_COL: 'configs',
  PROFILES_COL: 'profiles',
  PROFILEDATA_COL: 'profileData',
  PROFILE_UI_DATA: 'UIData',
  PROFILE_DEVICE_DATA: 'deviceData',
  ASSIGNED_DEVICES: 'assignedDevices',
  GROUPS: 'groups',
  DEVICES: 'devices',
  USERS: 'users',
  REMOTE_CONTROL: 'remoteControl',
  CONSOLE_INFO_DOC: 'consoleInfo',
  REMOTE_INFO_DOC: 'remoteInfo',
  DEVICE_PROPERTIES_DOC: 'deviceProperties',
  LICENSES: 'licenses',
  DEVICE_APPS: 'apps',
  DEVICE_APPS_ISTALLED: 'appsInstalled',
  SERVER_CONFIG_COL: 'server_config',
  APP_LATEST_VERSIONS_DOC: 'appLatestVersions',
  CLIENT_CONFIG_COL: 'client_config',
  SUPPORTED_MODELS: 'supportedModels',
  TENANT_CONFIG_COL: 'tenantConfig',
  LICENSE_CONFIG: 'licenseConfig',
  LGE_COL: 'LGE',
  EB_TRIAL_LICENSE_PREF: 'EBTrialLicensePref',
  DEMO_VIDEOS_REFERENCE: 'demoVideosReference',
  ZEBRAAPPS_COL: 'zebraApps',
  VERSION_COL: 'versions',
  RESOURCES_COL: 'resources',
  PACKAGENAME: 'com.zebra.mdna.oemconfig.release',
  VERSION: '52',
  APPRESTRICTIONJSON: 'appRestrictionJSON',
  ARTIFACT_DATA: 'artifactData',
  DASHBOARD: 'dashboard',
  BATTERY_HEALTH_STATUS: 'batteryHealthStatus',
  RELEASED_FEATURE_DETAILS: 'releasedFeatureDetails',
  LATEST_ARTIFACT: 'latestArtifact',
  OTHER_EMM_DETAILS: 'otherEmmDetails',
  DEVICE_COUNTS: 'deviceCounts',
  USER_PREFERENCE: 'userPreference',
  PAGE_PREFERENCE: 'pagePreference',
  TABLE_PREFERENCE: 'tablePreference',
  SUPPORTED_BUILDS: 'supportedBuilds',
  MINIMUM_SUPPORTED_BUILDS: 'minimumSupportedBuilds',
  DEVICE_USERS_COL: 'deviceUsers',
  FEATURE_PERMISSION: 'featurePermission',
  MY_APPS: 'myApps',
  ROLE_CONFIG: 'roleConfig',
  USER_PERMISSIONS: 'userPermissions',
  PG_TENANT_CONFIG_COL: 'tenantConfig',
  PG_CONFIG_COL: 'config',
  PG_CONFIG_DOC: 'passwordManager',
}

const LGE = {
  DEPLOYMENT_SIMPLE_STATUS: process.env.REACT_APP_DEPLOYMENT_SIMPLE_STATUS,
  CANCEL_DEPLOYMENT: process.env.REACT_APP_CANCEL_DEPLOYMENT,
  DEPLOYMENT_DETAILED_STATUS: process.env.REACT_APP_DEPLOYMENT_DETAILED_STATUS,
  SORT_API_URL: process.env.REACT_APP_SORT_API,
  DEVICES_API: process.env.REACT_APP_DEVICES,
}

const LICENSES = {
  LICENSE_ERROR: 'License Required',
}

const DEFAULT_SYSTEM_ROLES = ['Admin', 'Manager']

const DESIGNS = {
  ADD_DESIGN: process.env.REACT_APP_ADD_DESIGNS,
  GET_DESIGNS: process.env.REACT_APP_GET_DESIGNS,
}

const CHECK_LATEST_APP_VERSION = ['ZDPI', 'oemConfig', 'FOTA', 'com.zebra.commontransportlayer']

const CHECK_PACKAGE_SHORT_FORM = {
  CTL: 'com.zebra.commontransportlayer',
  ZDPI: 'ZDPI',
  oemConfig: 'oemConfig',
  FOTA: 'FOTA',
}

const DOC_URL_PROD = 'https://techdocs.zebra.com/zebradna/5-0'
const DOC_URL_STAGE = 'https://zebra-stage.github.io/zebradna/5-0'

const RESOURCES = {
  TECHDOCLINK: process.env.REACT_APP_ENV === 'production' ? `${DOC_URL_PROD}` : `${DOC_URL_STAGE}`,

  TECHDOCLINK_ABOUT:
    process.env.REACT_APP_ENV === 'production'
      ? `${DOC_URL_PROD}/about/`
      : `${DOC_URL_STAGE}/about/`,
  TECHDOCLINKREGISTERUSERGUIDE:
    process.env.REACT_APP_ENV === 'production'
      ? `${DOC_URL_PROD}/setup/#iiregisterwithzebracom`
      : `${DOC_URL_STAGE}/setup/#iiregisterwithzebracom`,
  SYSTEM_REQUIREMENT_LINK:
    process.env.REACT_APP_ENV === 'production'
      ? `${DOC_URL_PROD}/setup/#systemrequirements`
      : `${DOC_URL_STAGE}/setup/#systemrequirements`,
  GOOGLE_DRIVE_HELP_LINK:
    process.env.REACT_APP_ENV === 'production'
      ? `${DOC_URL_PROD}/setup/#myservicesnotes`
      : `${DOC_URL_STAGE}/setup/#myservicesnotes`,
  DEVICE_AUTO_NAMING:
    process.env.REACT_APP_ENV === 'production'
      ? `${DOC_URL_PROD}/usage/#deviceautonaming`
      : `${DOC_URL_STAGE}/usage/#deviceautonaming`,
}

let CONTENT_TYPE = {
  ENCODED: 'application/x-www-form-urlencoded',
  JSON: 'application/json',
}

const JOB_STATUS = {
  INPROGRESS: 'inProgress',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CREATED: 'created',
  CANCELLED: 'cancelled',
  SUCCESS: 'success',
  EXCEPTION: 'exception',
}

const JOB_STATUS_CODE = {
  4000: 'Device is not enrolled',
  4001: 'Bsp Version not found for this device. Please contact Administrator',
  4002: 'Patch Details not found for this device.. Please contact Administrator',
  4003: 'Device Information not found',
  4004: 'More than one record found for deriving the base Artifact. Please contact the Administrator.',
  4005: 'Device is already updated',
  4006: 'Artifact data not found for this device. Please contact Administrator. Arguments are in following format [baseline, patch, deviceModel, artifactSubType, ArtifactId]',
  4007: 'Artifact data not found for targeted. Please contact Administrator. Arguments are in following format [ArtifactId]',
  4008: 'Artifact data not found for targeted OS,patch,bsp version. Please contact Administrator. Arguments are in following format [deviceModel,TargetBsp,TargetPatch,TargetArtifactSubType,TargetOS]',
  4009: 'This Device does not support LGE. Please contact Administrator',
  4010: 'LGE is not supported in this BSP/Patch version you are trying to upgrade.Please contact Administrator',
  4011: 'This Device does not support LGE. Please contact Administrator',
  4012: 'Artifact data support Issue. Please contact Administrator',
  4013: 'Device is not entitled',
  4014: 'Device not report any status',
  4015: 'Target security patch level is older than source. Please select the latest one.',
  4016: 'More than one record found for deriving target Artifact. Please contact the Administrator.',
  4017: 'Provided device artifact sub type does not match with target artifact sub type',
  5000: 'NO DEPLYEMENT ID TO CANCEL',
  5001: 'CANCEL NOT FOR CURRENT DEPLOYEMENT ID',
  5002: 'CANCEL NOT POSSIBLE',
  5003: 'CONCURRENT REACH APPLY CANCEL',
  5004: 'SOME DEPLOYMENT IS INPROGRESS',
  5006: 'PROFILE IS NULL',
  5007: ' MODE NOT SUPPORTING',
  5008: 'BSP RESTRICTION FOR SPACE',
  5009: 'ZERO FILE SIZE',
  5010: 'REQUIRED SPACE NOT AVAILABLE',
  5011: 'REQUIRED SPACE CANNOT BE ZERO',
  5012: 'MEDIA SERVER NULL',
  5013: 'TOKEN NULL',
  5014: 'TOKEN KEY NULL',
  5015: 'TOKEN VALUE NULL',
  5016: 'CREDENTIALS NULL',
  5017: 'USERNAME NULL',
  5018: 'PASSWORD NULL',
  5019: 'ARTIFACT NULLFOTA',
  5020: 'ARTIFACT ZIP EXTENSION',
  5021: 'UPL ARTIFACT',
  5022: 'CAN NOT BE DUPLICATE',
  5023: 'ARTIFACT URL NULL',
  5024: 'ARTIFACT URL INCORRECT',
  5025: 'SCHEDULE NULL EMPTY',
  5026: 'MODE NULL EMPTY',
  5027: 'CURRENTDATE IS AFTER ENDDATE',
  5028: 'STARTDATE CANNOT COME AFTER ENDDATE',
  5029: 'INVALID DATE',
  5030: 'ENDDATE CAN NOT BE EMPTY',
  5031: 'INSTALL NULL',
  5032: 'NOT SUPPORTED',
  5033: 'SCHEDULE STARTDATE CANNOT EMPTY',
  5034: 'SCHEDULE ENDDATE CANNOT EMPTY',
  5035: 'DOWNLOAD STARTDATE CANNOT EMPTY',
  5036: 'LASTPOSTPONE DATE CANNOT EMPTY',
  5037: 'INSTALL STARTDATE CANNOT EMPTY',
  5038: 'SCHEDULE STARTDATE CANNOT COME AFTER STARTINSTALL DATE',
  5039: 'STARTINSTALLDATE CANNOT COME AFTER ENDSCHEDULEDDATE',
  5040: 'SCHEDULED START DATE CANNOT COME AFTERLASTPOSTPONEMENTDATE',
  5041: 'STARTDOWNLOADATE CANNOT COME AFTER STARTINSTALLDATE',
  5042: 'LASTPOSTPONEMENTDATE CAN NOT COME AFTER ENDSCHEDULEDDATE',
  5043: 'STARTDOWNLOADDATE CAN NOT COME AFTER LASTPOSTPONEMENTDATE',
  5044: 'INVALID LEAD TIME',
  5045: 'INVALID INSTALL TIMEWINDOW START',
  5046: 'INVALID INSTALL TIMEWINDOW END',
  5047: 'EQUAL INSTALL TIMEWINDOWSTART TIMEWINDOWEND',
  5048: 'TIMEWINDOW FINISHED FOR LAST DATE',
  5049: 'DOWNLOAD CANNOT NULL',
  5050: 'INVALID DOWNLOAD TIMEWINDOW START',
  5051: 'INVALID DOWNLOAD TIMEWINDOW END',
  5052: 'EQUAL DOWNLOAD TIMEWINDOWSTART TIMEWINDOWEND',
  5053: 'SCHEDULED STARTDATE CANNOT COME AFTER STARTDOWNLOADDATE',
  5054: 'STARTDOWNLOADDATE CA N NOT COME AFTER ENDSCHEDULED DATE',
  5055: 'NETWORK CANNOT BE EMPTY',
  5056: 'INVALID NETWORK TYPE',
  5057: 'INVALID BATTERY MINIMUM LEVEL',
  5058: 'INVALID BATTERY LEVEL',
  5059: 'TARGET DOWNLOADPATH NULL EMPTY',
  5060: 'UNSUPPORTED TARGET DOWNLOAD PATH',
  5061: 'TARGET BUILDID NULL EMPTY',
  5062: 'TARGET PATCHLEVEL NULL EMPTY',
  5063: 'ARTIFCATID NULL EMPTY',
  5064: 'RESET NULL EMPTY',
  5065: 'RESET UNSUPPORTED',
  5066: 'MODE UNSUPPORTED',
  5067: 'DEPLOYEMENT NULL EMPTY',
  5068: 'TRANSACTION NULL EMPTY',
  5069: 'TIMESTAMP NULL EMPTY',
  5070: 'ACTION NULL EMPTY',
  5071: 'UNSUPPORTED ACTION VALUE',
  5072: 'SOURCE NULL EMPTY',
  5073: 'SCHEMA NULL EMPTY',
  5074: 'UNSUPPORTED SCHEMA',
  5075: 'INAPPRORIATE SCHEMA',
  5076: 'CONTEXT NULL',
  5077: 'ADMIN CANCEL MSG',
  5078: 'USER CANCEL DOWNLOAD',
  5079: 'INSTALL AW',
  5080: 'DOWNLOAD COMPLETE',
  5081: 'MANIFEST PROGRESS',
  5082: 'REBOOT DURINGOSUPDATE',
  5083: 'REBOOT DURING DOWNLOA D IN PROGRESS',
  5084: 'ZDS DISABLED',
  5085: 'ZDS ENABLED',
  5086: 'DOWNLOAD YET TO BEGIN',
  5087: 'DOWNLOAD IN PROGRESS',
  5088: 'DOWNLOAD PAUSED WAITING TO RETRY',
  5089: 'DOWNLOAD PAUSED WAITING FOR NETWORK',
  5090: 'DOWNLOAD PAUSED QUEUED FOR WIFI',
  5091: 'DOWNLOAD PAUSED UNK NOWN',
  5092: 'DOWNLOAD FAILED WITH FILE NOT FOUND',
  5093: 'DOWNLOAD FAILED WITH ERROR UNKNOWN',
  5094: 'DOWNLOAD FAILED WITH ERROR FILE ERROR',
  5095: 'DOWNLOAD FAILED WITH ERROR UNHANDLED HTTP CODE',
  5096: 'DOWNLOAD FAILED WITH ERROR HTTP DATA ERROR',
  5097: 'DOWNLOAD FAILED WITH ERROR TOO MANY REDIRECTS',
  5098: 'DOWNLOAD FAILED WITH ERROR INSUFFICIENT SPACE',
  5099: 'DOWNLOAD FAILED WITH ERROR DEVICE NOT FOUND',
  5100: 'DOWNLOAD FAILED WITH ERROR CANNOT RESUME',
  5101: 'DOWNLOAD FAILED WITH ERROR FILE ALREADY EXISTS',
  5102: 'DOWNLOAD FAILED WITH UNKNOWN ERROR',
  5103: 'DOWNLOAD FAILED WITH ERROR DOWNLOAD RETRY TIMEOUT',
  5104: 'DOWNLOAD FAILED WITH INDEX OUT OF BOUNDS EXCEPTION',
  5105: 'URL EMPTY',
  5106: 'EXCEPTION IN DOWNLOADING FILE',
  5107: 'OTHER OSUPDATE PROGRESS',
  5108: 'MANIFEST SUCCESS',
  5109: 'MANIFEST FAILURE',
  5110: 'MX FAILED TO REBOOT',
  5111: 'OEMINFO DOESNOT RETURN ANY VALUE',
  5112: 'END DATE IS OVER',
  5113: 'INSTALL IS TRIGGERED',
  5114: 'END DATE OVER INSTALL RULE DOES NOT MET',
  5115: 'MX IS NOT READY EVEN AFTER MAX TIME OUT',
  5116: 'MX SERVICE IS NOT AVAILABLE',
  5131: 'MX SETTINGS FAILED',
  5117: 'FOTA READY',
  5118: 'FOTA UPDATED',
  5119: 'FOTA SETINGS SET PROPERLY',
  5120: 'FOTA SERVICE DISABLED',
  5121: 'FOTA SERVICE ENABLED',
  5122: 'FOTA CLIENT NOT IN CORRECT STATE',
  5123: 'REQUEST CANCELLED',
  5124: 'DOWNLOAD FAILED',
  5125: 'INSTALL SUCCESS MSG',
  5126: 'DOWNLOAD AW',
  5127: 'INSTALL IN PROGRESS MSG',
  5128: 'INSTALL FAILURE MSG',
  5129: 'WAITING FOR REBOOT MSG',
  5130: 'INVALID SESSION ID',
  5132: 'RECVD JSON',
  5133: 'INVALID JSON DATA',
  5134: 'ZDS SERVICE DISABLED',
  5135: 'ZDS SERVICE ENABLED',
  5136: 'DOWNLOAD PENDING',
  5137: 'SECURITY EXCEPTION WHILE DOWNLOADING',
  5138: 'EXCEPTION WHILE DOWNLOADING',
  5139: 'Waiting for OS update confirmation',
  5146: 'DEVICE NOT LICENSED',
  6000: 'DOWNLOAD FAILED WITH INVALID INSTALLATION TYPE',
  6001: 'DOWNLOAD FAILED WITH INVALID PACKAGE TYPE',
  6002: 'DOWNLOAD FAILED WITH INVALID REQ PROP',
  6003: 'INSTALL FAILURE AB STREAM',
  6004: 'INSTALL FAILURE CSP',
  6005: 'NETWORK RULES NOT MET RETRYING',
  6007: 'POWER RULES NOT MET RETRYING',
  6006: 'INSTALL RULES FAILED RETRY',
  6008: 'CANCEL FAILURE ',
  1107: 'OS Update has failed to install',
  1009: 'OS Update failed due to error while copying or transferring package',
  1113: 'OS update failed to install due to exception',
  1114: 'Eject SD/USB card from Settings when update is ongoing from external SD/USB card path',
  1124: 'Trigger Streaming OTA Full Upgrade in low battery - without connecting Charging source or with connecting USB Charging source',
  1125: 'Test OTA Full Downgrade on CC enabled device',
  1201: 'OS update has cancelled due to reboot',
  1202: 'Update cancelled ',
}

const REMOTE_CONTROL_STATUS = {
  CONSOLE_OFFER_INFO: 'consoleOfferInfo',
  CONSOLE_CANDIDATE_INFO: 'consoleCandidateInfo',
  CONSOLE_SESSION_CLOSED: 'consoleSessionClosed',
  REMOTE_ANSWER_INFO: 'remoteAnswerInfo',
  REMOTE_CANDIDATE_INFO: 'remoteCandidateInfo',
  REMOTE_SESSION_CLOSED: 'remoteSessionClosed',
}

const REMOTE_CONTROL_EXCEPTIONS = {
  ACTIVE_REMOTE_CONTROL_SESSION_EXCEPTION:
    'Unable to connect; device is busy. Please try again later.',
  MX_NOT_READY_EXCEPTION: 'Device is initializing. Please wait a moment.',
  ZWC_NOT_CONFIGURED_EXCEPTION:
    'Unable to access the Desktop View. Ensure that a supported device with ZWC installed is docked in a cradle with external monitor. For a full list of supported devices, see the ',
  KTI_PACKAGE_NOT_WHITELISTED_EXCEPTION: 'Device is initializing. Please wait a moment. ',
  DEVICE_NOT_DOCKED_EXCEPTION:
    'Unable to access the Desktop View. Ensure that a supported device with ZWC installed is docked in a cradle with external monitor. For a full list of supported devices, see the Zebra Support Portal',
  SECONDARY_SCREEN_NOT_CONNECTED_EXCEPTION:
    'Unable to access the Desktop View. Ensure that a supported device with ZWC installed is docked in a cradle with external monitor. For a full list of supported devices, see the Zebra Support Portal',
  LOWER_INJECTION_SERVICE_EXCEPTION: 'Device is initializing. Please wait a moment. ',
}

const REMOTE_CONTROL_EXCEPTION_ERRORCODE = {
  10100: REMOTE_CONTROL_EXCEPTIONS.ACTIVE_REMOTE_CONTROL_SESSION_EXCEPTION,
  10101: REMOTE_CONTROL_EXCEPTIONS.MX_NOT_READY_EXCEPTION,
  10102: REMOTE_CONTROL_EXCEPTIONS.KTI_PACKAGE_NOT_WHITELISTED_EXCEPTION,
  10103: REMOTE_CONTROL_EXCEPTIONS.ZWC_NOT_CONFIGURED_EXCEPTION,
  10104: REMOTE_CONTROL_EXCEPTIONS.DEVICE_NOT_DOCKED_EXCEPTION,
  10105: REMOTE_CONTROL_EXCEPTIONS.SECONDARY_SCREEN_NOT_CONNECTED_EXCEPTION,
  10106: REMOTE_CONTROL_EXCEPTIONS.LOWER_INJECTION_SERVICE_EXCEPTION,
}
const FIELD_LENGTH = {
  DEVICE_SETTINGS_NAME_MIN: 5,
  DEVICE_SETTINGS_NAME_MAX: 50,
  DEVICE_SETTINGS_DESCRIPTION_MAX: 500,
  DEVICE_SETTINGS_FEEDBACK_MAX: 500,
  ANDROID_UPDATES_JOB_NAME_MIN: 5,
  ANDROID_UPDATES_JOB_NAME_MAX: 50,
  ANDROID_UPDATES_DESCRIPTION_MAX: 500,
  ANDROID_UPDATES_NOTIFICATION_MAX: 255,
  APP_MANAGE_MIN: 5,
  APP_MANAGE_PACKAGE_MAX: 255,
  APP_MANAGE_URL_MAX: 2000,
  APP_NAME_MIN: 3,
  APP_NAME_MAX: 50,
  APP_PACKAGE_MAX: 99,
  APP_VERSION_MIN: 1,
  APP_DESCRIPTION_MAX: 500,
}

const SMARTREPO_JSON = {
  supportedModels:
    'CC600, CC6000, EC30, EC50, EC55, ET51, ET56, L10A, MC20, MC2200, MC2700, MC33x, MC33xR, MC33ax, MC93, PS20, TC21, TC26, TC52, TC52x, TC52ax, TC57, TC57x, TC72, TC77, TC8300, VC83 & WT6300',
}

const REGEX = {
  //CHAR_REGEX_NO_SPACE_ONLY: "[a-zA-Z][a-zA-Z ]+[a-zA-Z]$",
  NO_SPACE_REGEX: '^(?! )[A-Za-z0-9_!@#$&(){}<>?:;\'^%!\\-`.+,/" ]*(?<! )$',
  NUMBER_REGEX: '^[0-9]*$',
  APK_PACKAGE_REGEX: '^([A-Za-z]{1}[A-Za-z\\d_]*\\.)+[A-Za-z][A-Za-z\\d_]*$',
  URL_REGEX:
    '((http|ftp|https):\\/\\/)?[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?',
  ALPHABET_NUMBER_REGEX: /^[a-zA-Z0-9]{1,5}[a-zA-Z0-9_\-.\ ]*$/i,
  ALPHABET_NUMBER_SpecialChar_REGEX: /^[a-zA-Z0-9%$#&_@!.-]{1,5}[a-zA-Z0-9%$#&_@!_\-.\ ]*$/i,
}

const FEATURE_MENUS = {
  LICENSED_FEATURE: ['deviceSettings', 'androidUpdates'],
  ANDROID_UPDATE_FEATURE: ['androidUpdates'],
  ZDM_AS_DO_FEATURE: ['deviceSettings'],
  ZEBRA_AUTHORIZED_FEATURE: ['androidUpdates', 'licensing', 'deviceSettings'],
}
const DISABLED_FEATURE_MESSAGE = {
  DISABLE_ANDROID_UPDATE_MENU:
    'Lifeguard Enablement enrollment service is not available for Zebra CoreID accounts',
  DISABLE_ANDROID_UPDATE_MENU_ZEBRA:
    'LifeGuard Service is not available for Zebra.com email addresses.',
  DISABLE_ANDROID_UPDATE_MENU_NON_ZEBRA:
    'LifeGuard Service is initializing, please check back in a few minutes.',
  UNLICENSED_MENU: 'License Required',
  DISABLED_NO_ZDM_AS_DO: 'Feature not available; device is enrolled in third-party EMM.',
  UNSUPPORTED_BSP_OS:
    'Minimum OS version not met. Visit "Android Updates" to update to a supported version.',
  DISABLED_NOT_CONNECTED_TO_ZEBRA_SERVICES_TENANT:
    'Admin not connected to Zebra Services. Please go to My Services to set up',
  DISABLED_NOT_CONNECTED_TO_ZEBRA_SERVICES_ADMIN:
    'Admin not connected to Zebra Services. Please contact your Admin',
  PERMISSION_RESTRICTION_BY_ADMIN: 'You do not have permission to access.',
  NOACCESS_PERMISSION_TOOLTIP: 'You do not have permission to access.',
  DISABLE_MIX_SELECTION: 'This operation cannot be applied if the status of any device in the group is "In Progress."',
  DISABLE_HUNDRED_DEVICE_LIMIT: 'This operation supports no more than 100 devices at a time',
  LISENCE_REQUIRED_TOOLTIP: 'License Required',
  UPDATE_ZDNA_CLIENT_TOOL_TIP:
    'Update Client in progress. This may take some time. Please check back later',
}

const LGE_TOKEN_ISSUE_MESSAGE = {
  ADMIN_USER_V1:
    'The token for LifeGuard enablement is expired or invalid. The Admin must log in again to reauthorize.',
  ADMIN_USER_V2:
    'The token for LifeGuard enablement is expired or invalid. Admin must reauthorize on the My Services page.',
  TENANT_OWNER_V2:
    'The token for LifeGuard enablement is expired or invalid. Please reauthorize on the My Services page.',
}

const APPLY_SETTING_TYPE = {
  GUIDED_SETUP: 'guidedSetup',
  ADVANCED_SETUP: 'advancedSetup',
}

const APPLY_RXLOGGER_TYPE = {
  NEW_LOG: 'createNewLog',
  ADJUST_DEFAULT_SETTINGS: 'adjustDefaultLogSettings',
}

const MAX_LENGTH = {
  PROFILE_NAME: '40',
  PROFILE_DESCRIPTION: '150',
  DEVICE_AUTO_NAMING: 25,
}

const HIDE_BATTERY_HEALTH_STATUS = false
const BATTERY_UN_SUPPORTED_MODELS = ['TC15', 'TN28']

const SESSION_TIMEOUT = 30 * 60 * 1000

const INDEX_SEPERATOR = '$'
const PATH_LITERAL = '/'

const GOOGLE_DRIVE_TOKEN = {
  ACCESSTOKEN: 'valgdat',
  ACCESSTOKEN_EXPIRY: 'valgdate',
}
const ACTION_NAME = {
  START_REMOTE_CONTROL: 'START_REMOTE_CONTROL',
}
const REMOTE_CONTROL_MANAGEMENT = {
  ENABLE_AUDIO: false,
  ENABLE_VIDEO: false,
}

const ERROR_MESSAGE = {
  EXTRACTION_FAILED: 'Error: Not a valid APK',
  FILE_ACCESS: 'Got an error while getting access to the file, please try again',
  DRIVE_CONNECTION: 'Unable to connect to Google Drive',
  DUPLICATE_NAME: 'Duplicate App Name',
  UPLOAD_ERROR: 'Error while uploading, kindly try again',
  NOTE_APK: 'Error: Not a valid APK',
  ENROLLMENT_EXPIRE_ERROR: 'Error while expiring enrollment(s)',
  ENROLLMENT_DELETE_ERROR: 'Error while deleting enrollment(s)',
  DEVICE_USER_UPDATE_ERROR: 'Error occured while updating device user(s)',
}

const REMOTE_VIDEO_SETTINGS = {
  defaultHeight: 1280,
  defaultWidth: 720,
  PORTRAIT: '1',
  LANDSCAPE: '2',
}

const category = {
  ZEBRA_APPS: 'zebraApps',
  THIRD_PARTY_APPS: 'thirdPartyApps',
}

const DEVICE_SELECTION_COUNT = {
  APP_SETUP_DEVICE_SELECTION_COUNT: 2500,
  DEVICE_SETTING_SELECTION_COUNT: 2500,
  ALLOCATION_SERIALNUMBER: 1000,
}

const DEVICE_SELECTION_TOOL_TIP_MSG = 'You have reached the maximum limit of 2500 devices'
const SERIAL_NUMBER_TOOLTIP_MESSAGE =
  'You have reached the max 1K device selection for serial number preference'

const zebraApp = {
  DDT: 'com.symbol.selfdiagnostics',
  DDT1: 'com.zebra.mdna.selfdiagnostics',
  EKB: 'com.zebra.mdna.enterprisekeyboard',
  EHS: 'com.symbol.enterprisehomescreen',
  EHS1: 'com.zebra.mdna.enterprisehomescreen',
}
const zebraAppNames = {
  DDT: 'DDT',
  EKB: 'EKB',
  EHS: 'EHS',
}

const ZEBRA_APPS_PACKAGE_NAME = {
  DDT: 'com.symbol.selfdiagnostics',
  DDT1: 'com.zebra.mdna.selfdiagnostics',
  EKB: 'com.zebra.mdna.enterprisekeyboard',
  EHS: 'com.symbol.enterprisehomescreen',
  EHS1: 'com.zebra.mdna.enterprisehomescreen',
  EB: 'com.zebra.mdna.enterprisebrowser',
  ZWC: 'com.zebra.workstationconnect.release',
  ELS: 'com.zebra.mdna.els',
}
const deviceEnrollmentCategory = {
  ZDNA_EMM: '1',
  THIRD_PARTY_EMM: '2',
  ZERO_TOUCH: '3',
}

const GA_TRACK_ID = process.env.REACT_APP_GA_TRACK_ID

const TOGGLE_TYPE = {
  RC_DEVICE_VIEW: 0,
  RC_DESKTOP_VIEW: 1,
}

const BADGE_COLOR_CODES = {
  green: { badge: '#00864F', text: '#1D1E23', bgcolor: '#ECFFF7', border: '#84DAB6' },
  gray: { badge: '#3C414B', text: '#1D1E23', bgcolor: '#F3F6FA', border: '#E0E3E9' },
  gray_light: { badge: '#AFB6C2', text: '#929AA9', bgcolor: '#F3F6FA', border: '#EDEFF4' },
  red: { badge: '#D70015', text: '#1D1E23', bgcolor: '#FFF0F1', border: '#FF8692' },
  blue: { badge: '#0073E6', text: '#1D1E23', bgcolor: '#F1F8FF', border: '#7EBEFF' },
  yellow: { badge: '#FF9500', text: '#1D1E23', bgcolor: '#FFFAF2', border: '#FFD396' },
  purple: { badge: '#FF9500', text: '#1D1E23', bgcolor: '#F7F0FF', border: '#CEA4FF' },
}


const STATUS_CODE = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  PRECONDITION_FAILED: 412,
  LICENSE_SUCCESS: 1001,
  LICENSE_FAILED: 1003,
  LICENSE_PARTIAL_SUCCESS: 1002,
}
const STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  ERROR: 'error',
}

const ZEBRA_ENROLLMENT_STATUS = {
  ENROLLED: 'enrolled',
  NOT_ENROLLED: 'notEnrolled',
  ENROLLING: 'enrolling',
}

const CURRENT_DO = {
  ZDM: 'ZDM',
  OTHERS: 'others',
}

const zDNA_FEATURE = 'dnacloud-feature-std'
const restrictedColumnsCount = 1
const VERSION_NOT_SUPPORTED_MSG =
  'This version of the application does not support configuration when installed on devices with a third-party EMM.'
const LGE_ENROLL_BUFFER = 2 * 60 * 1000 //2 minutes
const LGE_ENROLL_WINDOW = 15 * 60 * 1000 //15 mins

const ERROR_MESSAGES_LOGIN = {
  3001: 'Users from this domain are no longer permitted',
  3002: 'Invalid request, kindly try again with proper data',
  3003: 'User not authenticated; Invalid request',
  3004: 'An error occurred while attempting to log in. Kindly try again.',
  3005: 'Duplicate tenants found',
  3006: 'Your invitation has expired! Contact admin for assistance.',
}

const REDIRECTION_FUNCTION = {
  PROCESS_TOKEN: 'processtoken',
}

const REDIRECTION_URL = {
  LOGIN_URL_V2: '/auth/login',
  LOGIN_URL_V1: '/auth/login/legacy',
}

const USER_STATUS_MAP = {
  Joined: 'Joined',
  Invited: 'Invited',
  Expired: 'Expired Invite',
}

const YOUTUBE_LINKS = {
  AUTHORIZATION_WALKTHROUGH: 'https://youtu.be/25zaBeuDdz4',
  INTRO_TO_ZDNA: 'https://youtu.be/KBqm9SbA9dQ',
}

const DEVICE_USER_TYPE = {
  PERSONALL_ASSIGNED: 'Personally Assigned',
  SHARED_DEVICE: 'Shared Device',
}

const DEFAULT_HIDDEN_ZEBRA_APPS = ['com.zebra.mdna.els']

const DEFAULT_ZEBRA_APPS = [
  'com.symbol.selfdiagnostics',
  'com.zebra.mdna.enterprisebrowser',
  'com.symbol.enterprisehomescreen',
  'com.zebra.mdna.enterprisekeyboard',
  'com.zebra.workstationconnect.release',
  'com.symbol.devicecentral',
]

const APPPACKAGENAMES = {
  DEVICE_CENTRAL: 'com.symbol.devicecentral',
  DEVICE_TRACKER: 'com.zebra.mdna.devicetrackercloud',
  WI_AGENT: 'com.zebra.wirelessinsights.agent',
}

const LICENSE_SOURCE = {
  GET_LICENSES: '1',
  ADD_LICENSES: '2',
  SYNC_LICENSES: '3',
}

const DEVICE_USERS_EXPIRY_CAL = 60 * 60 * 1000 // 1 hour

const LICENSE_MICROSERVICE_MSG = {
  LICENSE_SERVICE_DOWN: 'Connection to the licensing server is lost. Please try again later.',
  GET_LICENSE_ERROR: 'Error while fetching licenses',
  ADD_LICENSE_ERROR: 'Error while adding the Flexera CLS ID',
  ALLOCATE_LICENSE_ERROR: 'Error occurred while allocating licenses',
  ALLOCATE_LICENSE_SUCCESS: 'Allocated licenses successfully',
  REFRESHING_LICENSE_ERROR: 'Error while performing License Refresh',
  REFRESHING_LICENSE_SUCCESS: 'Refreshed successfully',
}

const LICENSE_MSG_CODE = [4023, 4016, 4027, 4028]
// 4023: Tenant claiming an already claimed cls, 4016: no licenses to claim
// 4027: Incorrect Cls Instance, 4028: Claiming future dated license

const JOB_TYPE = {
  APPLY_DEVICE_SETTINGS_WIZARD: 'APPLY_DEVICE_SETTINGS_WIZARD',
  APPLY_DEVICE_SETTINGS_ADVANCED: 'APPLY_DEVICE_SETTINGS_ADVANCED',
  APPLY_THIRD_PARTY_AND_ZEBRA_APPS: 'APPLY_THIRD_PARTY_AND_ZEBRA_APPS',
  DELETE_DEVICE_SETTINGS: 'DELETE_DEVICE_SETTINGS',
  DELETE_APP_SETUP_PROFILES: 'DELETE_APP_SETUP_PROFILES',
}

const JOB_STATE = {
  CREATING: 'creating',
  DELETING: 'deleting',
}

const USER_ROLE_MANGEMENT_MSG = {
  ROLE_DELETE_SUCCESS: 'Role deleted successfully',
  ROLE_DELETE_ERROR: 'Error occured while deleting the role',
}

const PERMISSIONS = {
  EDITABLE: 'Editable',
  NO_ACCESS: 'No Access',
  VIEW_ONLY: 'View Only',
  VIEW_WITH_REMOTE_CONTROL: 'View With Remote Control',
}

const PAGE_NAMES = {
  DEVICE_USERS: 'Device Users',
  MY_SERVICES: 'My Services',
  MY_DEVICES: 'My Devices',
}

const PROFILES_BULK_SELECTION_COUNT = {
  APP_SETUP_ACTIONS_MAX_COUNT: 20,
}

const LONG_RUNNING_LOADER_MSG = {
  MY_APP_LOADER_MESSAGE: 'App Setup profile creation in progress...',
  DEVICE_SETTING_LOADER_MESSAGE: 'Setting profile creation in progress...',
}
const HTTPS_CALLABLE_TIMEOUT = 540000 // 540 seconds or 9 minutes to override default timeout

const CONNECTION_STATUS = {
  ONLINE: 'Online',
  OFFLINE: 'Offline'
}

module.exports = {
  PROFILE_TYPE,
  DATABASE,
  FIELD_LENGTH,
  REMOTE_CONTROL_STATUS,
  REMOTE_CONTROL_EXCEPTIONS,
  REMOTE_CONTROL_EXCEPTION_ERRORCODE,
  SMARTREPO_JSON,
  REGEX,
  FEATURE_MENUS,
  APPLY_SETTING_TYPE,
  APPLY_RXLOGGER_TYPE,
  LGE,
  CONTENT_TYPE,
  JOB_STATUS_CODE,
  JOB_STATUS,
  MAX_LENGTH,
  HIDE_BATTERY_HEALTH_STATUS,
  RESOURCES,
  INDEX_SEPERATOR,
  GOOGLE_DRIVE_TOKEN,
  ACTION_NAME,
  REMOTE_CONTROL_MANAGEMENT,
  DISABLED_FEATURE_MESSAGE,
  LGE_TOKEN_ISSUE_MESSAGE,
  ERROR_MESSAGE,
  REMOTE_VIDEO_SETTINGS,
  zebraApp,
  category,
  zebraAppNames,
  ZEBRA_APPS_PACKAGE_NAME,
  SESSION_TIMEOUT,
  BATTERY_UN_SUPPORTED_MODELS,
  GA_TRACK_ID,
  deviceEnrollmentCategory,
  TOGGLE_TYPE,
  BADGE_COLOR_CODES,
  LICENSES,
  DESIGNS,
  STATUS_CODE,
  STATUS,
  zDNA_FEATURE,
  restrictedColumnsCount,
  VERSION_NOT_SUPPORTED_MSG,
  PATH_LITERAL,
  ZEBRA_ENROLLMENT_STATUS,
  CURRENT_DO,
  LGE_ENROLL_BUFFER,
  ERROR_MESSAGES_LOGIN,
  REDIRECTION_FUNCTION,
  REDIRECTION_URL,
  USER_STATUS_MAP,
  YOUTUBE_LINKS,
  DEVICE_USER_TYPE,
  DEFAULT_HIDDEN_ZEBRA_APPS,
  DEFAULT_ZEBRA_APPS,
  LICENSE_SOURCE,
  DEVICE_USERS_EXPIRY_CAL,
  LICENSE_MICROSERVICE_MSG,
  LGE_ENROLL_WINDOW,
  LICENSE_MSG_CODE,
  JOB_TYPE,
  DEVICE_SELECTION_COUNT,
  DEVICE_SELECTION_TOOL_TIP_MSG,
  JOB_STATE,
  PAGES_LIST,
  DEFAULT_SYSTEM_ROLES,
  APPPACKAGENAMES,
  USER_ROLE_MANGEMENT_MSG,
  PERMISSIONS,
  PAGE_NAMES,
  CHECK_LATEST_APP_VERSION,
  CHECK_PACKAGE_SHORT_FORM,
  SERIAL_NUMBER_TOOLTIP_MESSAGE,
  PROFILES_BULK_SELECTION_COUNT,
  LONG_RUNNING_LOADER_MSG,
  HTTPS_CALLABLE_TIMEOUT,
  CONNECTION_STATUS
}
